export function getViewConfig() {
  const myViewConfig = {
    version: "1.0.15",
    name: "Meta-analysis, Azimuth 2022",
    description: "Meta-analysis of 10 datasets of healthy and diseased human lung",
    datasets: [],
    initStrategy: "auto",
    coordinationSpace: {
      embeddingType: {
        UMAP: "UMAP"
      },
      obsType: {
        A: "cell"
      },
      featureType: {
        A: "gene"
      },
      featureValueType: {
        A: "expression"
      },
      embeddingObsRadius: {
        A: 3
      },
      embeddingObsRadiusMode: {
        A: "manual"
      }
    },
    layout: [
      {
        component: "obsSets",
        h: 6,
        w: 2,
        x: 10,
        y: 6,
        coordinationScopes: {
          obsType: "A"
        },
        uid: "A"
      },
      {
        component: "obsSetSizes",
        h: 6,
        w: 4,
        x: 8,
        y: 1,
        coordinationScopes: {
          obsType: "A"
        },
        uid: "B"
      },
      {
        component: "scatterplot",
        h: 12,
        w: 8,
        x: 0,
        y: 0,
        coordinationScopes: {
          embeddingType: "UMAP",
          obsType: "A",
          featureType: "A",
          featureValueType: "A",
          embeddingObsRadius: "A",
          embeddingObsRadiusMode: "A"
        },
        uid: "C"
      },
      {
        component: "featureList",
        h: 6,
        w: 2,
        x: 8,
        y: 6,
        coordinationScopes: {
          featureType: "A"
        },
        uid: "E"
      }
    ]
  };
  return myViewConfig;
}
