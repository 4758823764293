import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

export default function Header() {
  const { keycloak } = useKeycloak();
  const history = useNavigate();

  // define a function to handle click event
  const handleUploadClick = () => {
    history("/upload"); // redirect to /upload route
  };
  return (
    <h1 id="header">
      <Link to={"/"} id="header-text">
        <div>
          <img src="https://www.svgrepo.com/show/508090/home-alt.svg" alt="home" width="30px" />
        </div>
        <div>Data Visualizations</div>
      </Link>
      <div>
        {!keycloak.authenticated && <button onClick={() => keycloak.login()}>Login</button>}

        {!!keycloak.authenticated && (
          <>
            <button onClick={() => keycloak.logout()}>Logout</button>
            <button onClick={handleUploadClick}>Upload Dataset</button>
          </>
        )}
      </div>
    </h1>
  );
}
