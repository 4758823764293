import { Vitessce, VitessceConfig } from "vitessce";
import { getViewConfig } from "../get-view-config";
import { useLoaderData, useParams } from "react-router-dom";
import { getFileName } from "../helpers/filename";

export default function Visualization() {
  const config = useLoaderData();
  const { id } = useParams();
  return (
    <>
      <div className="dataset-title">
        <img src="https://www.svgrepo.com/show/50789/cell.svg" width="25" alt="Logo icon" />
        {getFileName(id)}
      </div>
      <Vitessce config={config.config} theme="light" />
    </>
  );
}

export async function loader(id) {
  const visualizationConfig = getViewConfig();
  const vc = VitessceConfig.fromJSON(visualizationConfig);
  vc.addDataset("my-dataset").addFile({
    url: process.env.REACT_APP_API_URL + "items/" + id,
    fileType: "anndata.zarr",
    coordinationValues: {
      obsType: "cell",
      featureType: "gene",
      featureValueType: "expression",
      embeddingType: "UMAP"
    },
    options: {
      obsFeatureMatrix: {
        path: "X"
      },
      obsEmbedding: {
        path: "obsm/X_umap"
      },
      featureLabels: {
        path: "var/gene_labels"
      },
      obsSets: [
        {
          name: "Cell Type",
          path: "obs/Celltypist_Cell_Type_Ontology_Name"
        },
        {
          name: "Sample Name",
          path: "obs/sample_id"
        },
        {
          name: "Sample Tissue",
          path: "obs/sample_tissue"
        },
        {
          name: "Donor Disease",
          path: "obs/donor_disease_doid_name"
        },
        {
          name: "Donor Treatment",
          path: "obs/donor_treatment"
        },
        {
          name: "Leiden",
          path: "obs/leiden/codes"
        },
        {
          name: "Donor Sex",
          path: "obs/donor_sex"
        },
        {
          name: "Donor Pathology",
          path: "obs/sample_pathology_ncit_name"
        }
      ]
    }
  });
  const config = vc.toJSON();
  return { config };
}
