import React from "react";
import PrivateRoute from "./helpers/PrivateRoute";
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Dashboard from "./routes/Dashboard";
import Visualization, { loader as visualizationConfigLoader } from "./routes/Visualization";
import ErrorPage from "./error-page";
import FileUpload from "./components/Upload";

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <>
        <Header />
        <PrivateRoute>
          <Outlet />
        </PrivateRoute>
      </>
    ),
    errorElement: (
      <>
        <Header /> <ErrorPage />
      </>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="/dashboard" />
      },
      {
        path: "/dashboard",
        element: <Dashboard />
      },
      {
        path: "visualization/:id",
        element: <Visualization />,
        loader: ({ params }) => visualizationConfigLoader(params.id)
      },
      {
        path: "/upload",
        element: <FileUpload />
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
