import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getFileName } from "../helpers/filename";

export default function Dashboard() {
  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "items").then((res) => {
      res.json().then((json) => {
        setDatasets(json);
      });
    });
  }, []);
  return (
    <>
      <nav id="visualization-list">
        {datasets.map((id) => (
          <Link to={`/visualization/${id}`} key={id}>
            <div className="visualization-list-item">
              <div>{getFileName(id)}</div>
              <div>
                <img
                  src="https://www.svgrepo.com/show/50789/cell.svg"
                  width="100"
                  alt="Logo icon"
                />
              </div>
            </div>
          </Link>
        ))}
      </nav>
    </>
  );
}
