import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak";

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  if (keycloak.authenticated) {
    if (config) {
      if (config.headers) {
        config.headers.Authorization = "Bearer " + keycloak.token;
      } else {
        config.headers = {
          Authorization: "Bearer " + keycloak.token
        };
      }
    } else {
      config = {
        headers: {
          Authorization: "Bearer " + keycloak.token
        }
      };
    }
  }
  return originalFetch(resource, config);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ReactKeycloakProvider authClient={keycloak}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ReactKeycloakProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
