import React, { useState } from "react";

// Define the FileUpload component
function FileUpload() {
  // Use the useState hook to create a state variable for the selected file
  const [selectedFile, setSelectedFile] = useState();

  // Define a function to handle file selection
  const handleFileChange = (event) => {
    // Get the selected file from the event object
    const file = event.target.files[0];

    // Check if the file exists and if its extension is .h5ad
    if (file && file.name.endsWith(".h5ad")) {
      // If the file meets the requirements, update the selectedFile state variable
      setSelectedFile(file);
    } else {
      // If the file does not meet the requirements, show an alert
      alert("Please select a .h5ad file");
    }
  };

  // Define a function to handle file upload
  const handleFileUpload = () => {
    // Create a new FormData object
    const formData = new FormData();

    // Append the selected file to the FormData object
    formData.append("file", selectedFile);

    // Send a POST request to the server with the FormData object as the body
    fetch(process.env.REACT_APP_API_URL + "upload", {
      method: "POST",
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        // Log the server's response to the console
        console.log(data);
        // TODO: Handle the response from the server
      })
      .catch((error) => {
        // Log any errors to the console
        console.error(error);
        // TODO: Handle the error
      });
  };

  // Render the component
  return (
    <div>
      {/* Render a file input and set its onChange handler to the handleFileChange function */}
      <input type="file" onChange={handleFileChange} />

      {/* If a file has been selected, render an upload button and set its onClick handler to the handleFileUpload function */}
      {selectedFile && <button onClick={handleFileUpload}>Upload</button>}
    </div>
  );
}

// Export the FileUpload component
export default FileUpload;
